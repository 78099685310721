// variable overrides
//colors
$primary-bg: #F5F5F5;
$main-font: #333333;
$secondary-font: #525252;
$join-btn: #5ABED1;
$log-btn: #E2FAFA;

// universal
body {
  font-family: 'Lato', sans-serif !important; 
}
.wrapper {
  width: 87%;
  margin:0 auto;
}
.our-reach h3,
.purpose h3,
.areas-of-focus h3,
.purpose-content h3,
.modules h3 {
  color: $main-font;
  font-weight: 600;
}


// visited links
//   nav a:visited,
// .list-unstyled a:visited {
//   color: #884488 !important;
// }
// links with the mouse cursor over it

.list-unstyled a:hover,
// links tabbed to on a keyboard
nav a:focus,
.list-unstyled a:focus,
/* selected link */
nav a:active,
.list-unstyled a:active {
  color: $main-font !important;
  text-decoration: none;
}

// nav a {
//   position: relative;
//   display: block;
//   color: $secondary-font !important;
//   font-size: 17px;
//   text-decoration: none;
//   transition: 0.5s;

//   &::after {
//     position: absolute;
//     content: "";
//     top: 100%;
//     left: 0;
//     width: 100%;
//     height:2px;
//     background: #5ABED1;
//     transform: scaleX(0);
//     transform-origin: right;
//     transition: transform 0.5s;
//   }

//   &:hover {
//     color: #5ABED1;
//   }

//   &:hover::after {
//     transform: scaleX(1);
//     transform-origin: left;
//   }
// }

.container-fluid {
  overflow: hidden;
}
.container {
  overflow: hidden;
}

header,
nav,
.navigation.container-fluid {
background: $primary-bg;
}

.navigation.container-fluid {
  padding: 1rem 7% 0 7%;
}
.about-text,
.learning-text {
  width: 50%;
}

.header-text h1,
.learning-text h1,
.about-text h1,
.header-text p,
.learning-text p,
.about-text p {
color: $main-font !important;
}
ul {
  list-style-type: none;
 }
 .nav-logo img {
  width: 8rem;
}
img {
  max-width:100%;
}

//navigation
.navigation{
  padding-top:13px;
} 

// links with the mouse cursor over it
.join.btn.btn-lg:hover,
.header-join.btn.btn-lg:hover {
  background-color: darken($join-btn , 20%);
}
.login.btn.btn-lg:hover {
  background-color: darken($log-btn , 10%);
}

.btn.btn-primary:hover,
.btn.btn-primary:active {
  background-color: darken($log-btn , 10%)!important;
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
  color: #054054!important;
}
.btn.btn-primary {
  background-color: $log-btn;
}

// .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
//     background-color: darken($log-btn , 10%)!important;
//     color: #054054 !important;
// }

.join.btn.btn-lg,
.login.btn.btn-lg,
.header-join.btn-lg {
border: none;
border-radius: 1.6rem;
}
.login.btn.btn-lg,
.join.btn.btn-lg {
  padding: 6px 24px;
  font-weight: 300;
}
.join.btn.btn-lg,
.header-join.btn.btn-lg {
background-color: $join-btn;
color: white;
}
.login.btn.btn-lg {
background-color: $log-btn;
color: $main-font;
width: 13rem;
display: flex;
justify-content: flex-end;
}
.join.btn.btn-lg {
  position:absolute;
}
.nav-item {
  font-size:18px;
  font-weight: 350;
  padding-left: 30px;
}
.form-inline {
  position: relative;
}
.navbar-light .navbar-toggler {
  border: none;
}
.nav-image {
  width: 8rem;
}
.navbar.navbar-expand-lg {
  padding: 1.6rem 0;
}
#navbarNav {
  justify-content: space-evenly;
}

//header
.header-text p,
.about-text p,
.learning-text p {
  font-size: 23px;
  font-weight: 300;
  // line-height:2.2rem;
  color: #646464;
}
.header-join.btn.btn-lg {
  font-weight: 600;
  padding: .9rem 3rem;
}
.header-text h1,
.learning-text h1,
.about-text h1,
.header-text p,
.learning-text p,
.about-text p {
  padding-bottom: 10px;
}

.header-text h1,
.learning-text h1,
.about-text h1 {
  font-size: 44px ;
  font-weight: 600;
}

.about-header {
  position: relative;
  padding: 9.2% 0;
  overflow: hidden;
}
.header-springboard-logo {
  margin-bottom: 3rem;
}
  

.home-header {
  position: relative;
  padding: 12% 0;
  overflow: hidden;
}
.header-image {
  position: absolute;
  width: 60%;
  right: -15%;
  bottom: -7%;
}
.flair-home-1 {
  position: absolute;
  width: 11%;
  bottom: 0;
  left: 44%;
}


//logo section 
.logo img {
  width: 10rem;
}
.logos {
  padding: 4rem 0;
  
}

#logos {
  overflow: hidden;
}



// our reach section 

.purpose {
  position:relative;
}

.our-reach.container-fluid {
  padding: 6% 0;
  position:relative;
}
.reach-title,
.purpose-title {
  font-size: 13px;
  color: #A3A3A3;
  font-weight: 500;
  margin-bottom:.1rem;
}
.reach-header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.3rem;
}
.reach-text {
  font-size: 18px;
  color: $secondary-font;
}
.reach-image  {
  position: absolute;
  right: 52%;
  bottom: 3%;
  width: 54%;
}
.stat-container {
  margin: 1.7rem 0;
  font-size: 18px;
}
.stat-container p {
  margin-bottom: 0;
}
.percentage.youth-completion p,
.percentage.facilitator p,
.percentage.youth-satisfaction p{
  color: white;
}
.percentage.youth-completion {
  background-color: #5CC4C5;
}
.percentage.facilitator {
  background-color: #DD1B5F;
}
.percentage.youth-satisfaction {
  background-color: #582669;
}
.percentage {
  width: 10rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 1.6rem;
  color: white;
  font-weight: 500;
}
.image-container {
  position: relative;
}
.stat-image {
  position: absolute;
  max-width: 5.5rem;
}
.percentage p {
  margin: 0;
}


// hub action section
.row.card-row.flex-wrap {
  width: 100%;
}
.card-deck .card {
  border-radius: 5px;
}
.card {
  -webkit-box-shadow: 10px 10px 5px -6px rgba(234,234,234,0.75);
  -moz-box-shadow: 10px 10px 5px -6px rgba(234,234,234,0.75);
  box-shadow: 10px 10px 5px -6px rgba(234,234,234,0.75);
  border-radius: 1rem;
}
.card-body {
  margin: 3.5rem 0;
}
.card-image {
  height:13rem;
  width:13rem;
  border-radius: 50%;
}
.card-body h5 {
  font-size: 24px;
  font-weight: 600;
}
.card-body p {
  font-size: 17px;
  margin: 1.3rem 0;
}
.btn.btn-primary {
  border:none;
  background-color: #E2FAFA;
  color:#054054;
}


// call to action section
.call-to-action {
  padding:10% 0;
  position: relative;
  overflow: hidden;
}
.call-text h1 {
  margin-bottom: 3rem;
  font-weight: 600;
}
.flair-1 {
  width: 12%;
  position: absolute;
  bottom: -4%;
  left: -3;
  left: -2%;
}
.flair-2 {
  position: absolute;
  width: 4rem;
  top: 7%;
  right: 7%;
}


//footer 
footer {
  background-color: $primary-bg;
  padding: 1.5% 0;
}
.main-footer-content {
  padding:4% 0;
}
.list-unstyled a {
  color: #575757;
  font-weight:100 !important;
  margin-bottom:.4rem;
  text-decoration: none;
}
.footer-copyright p {
  color:#8D8D8D;
  padding: 1% 0;
  font-size: 15px;
}
.footer-springboard-logo ,
.logo.footer-hub-logo img {
  width: 5rem;
}
.main-footer-content {
  border-bottom: 1px solid #DDDDDD;
}
.footer-highlight {
  color: #525252;
  font-weight: 700;
  margin-bottom: 6px;
}
.footer-icons {
  margin: 10px 0;
}

i {
  margin-right: 12px;
  font-size: 18px;
  color: #A3A3A3;
}


// ABOUT PAGE STYLES

// about header

.about-text p {
  font-size: 23px;
}
.about-header {
  position: relative;
  overflow: hidden;
}
.flair-about-1 {
  width: 13%;
  position: absolute;
  top: 49%;
  left: -2%;
}
.flair-about-2 {
  position: absolute;
  width: 9%;
  top: 0%;
  right: -3%;
}
.header-image-2 {
  position: absolute;
  bottom: 0;
  width: 40%;
  right: -15%;
}
.purpose-header,
.areas-of-focus h1 {
  font-size: 28px;
  font-weight: 600;
}
.purpose-content h4 {
  font-size: 13px;
  color: #A3A3A3;
  font-weight: 500;
  margin-bottom:.5rem;
}
.purpose-title {
  margin-bottom: 9px;
}
.purpose-content p {
  font-size: 18px; 
  color: $secondary-font;
}
.purpose-content {
  padding:8% 0;
}

// eyebrow section

.eyebrow,
.purpose {
  padding:3% 0;
}

//areas of focus 
.areas-of-focus h1 {
  margin-top:2rem;
}
.area-text {
  width: 64%;
}
.area-text h2 {
  font-size: 20px;
  font-weight: 600;
}
.area-text p {
  font-size: 17px;
}
.area-image {
  width:6rem;
}
.areas {
  padding:6% 0 3% 0;
}
.area {
  margin-bottom: 4rem;
}


// our funders 

.our-funders {
  // padding: 9% 0;
  background-color: $primary-bg;
}
.funder {
 margin-bottom: 45px;
}
.ontario {
  max-width:19rem;
}
// .health {
//   max-width:12rem;
// }
.justice {
  max-width:22rem;
}
.trillium {
  max-width: 13rem;
}
.telus {
  max-width: 8.5rem;
}
.rbc {
  max-width: 12.5rem;
}
.td {
  max-width:8.5rem;
}
.row2 {
  width: 100%;
}
.row1 {
  width: 100%;
  margin-bottom: 10px;
}
.rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 2rem;
}
.row.row2,
.row.row1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.our-funders h1 {
  font-size: 23px;
  font-weight: 600;
  margin: 6rem 0 4rem 0;
}


// LEARNING PLATFORM PAGE

.learning-header {
  position: relative;
  overflow:hidden;
  padding: 9% 0;
}
.header-image-3 {
  position: absolute;
  width: 33%;
  bottom: 0;
  left: -12%;
}
.learning-header-flair-2 {
  position: absolute;
  left: 90%;
  width: 13%;
  top: 0%;
}


// explore modules section 
.modules.container-fluid {
  background-color: $primary-bg;
  padding: 3% 0;
}
.module-text p {
  font-size: 18px;
  color: $secondary-font;
}
.modules h3 {
  padding: 1rem 0;
}

// learning technologies section

.learning-tech {
  margin-top: 4rem;
}

.tech h2 {
  font-size: 18px;
  margin-bottom: 0;
}
// .tech-image {
//   width: 5rem;
// }
.area-text {
  color: $main-font;
}
.technologies {
  height: 24rem;
}
.tech-image {
  width: 5rem;
}
.tech-text {
  width: 64%;
  // margin-left: 17px;
}

// testimonial section 

.testimonial {
  background-color: #582469;
  padding: 5% 0;
}
.testimonial p {
  color: white;
  font-size: 17px;
}
.quote {
  font-style: italic;
}

// additional footer styles 



.footer-copyright a {
  color: #8D8D8D;
}
.footer-copyright a:hover,
.footer-copyright a:active {
  color: $secondary-font;
  text-decoration: none;
}


// RESPONSIVE

@media(max-width: 320px) {
  .flair-2 {
    position: absolute;
    width: 4rem;
    top: 2%;
    right: 7%;
}
.testimonial-portrait {
  width: 80%;
}
}

@media (max-width: 415px) {
  .header-text h1, 
  .about-text h1,
  .learning-text h1  {
    font-size: 28px !important;
    text-align:left;
  }
  .about-text p,
  .header-text p,
  .learning-text p {
    font-size:18px !important;
    text-align:left;
  }
  .reach-text,
  .percentage p{
    font-size:17px;
  }
  .flair-home-1 {
    position: absolute;
    width: 22%;
    bottom: 0;
    left: 15%;
  }
  .reach-header {
    font-size: 23px;
  }
  .stat-image {
    width:3.5rem;
  }
  .percentage {
    width: 6.5rem;
  }
  .stat-container {
    margin: 1rem 0;
    font-size: 18px;
  }
  .stat-container p {
    font-size: 15px;
  }
  .call-text h1 {
    font-size: 23px;
  }
  .area {
    margin-bottom: 1rem;
  }
  .areas-of-focus h1 {
    margin: 0;
  } 
  .tech h2 {
    font-size: 16px;
  }
  .testimonial p {
    color: white;
    font-size:15px;
  }
  .card-body {
    margin: .5rem 0;
  }
  .card-body h5 {
    font-size: 20px;
    font-weight: 600;
  }
  .funder {
    margin-bottom: 30px;
  }
  .our-funders h1 {
    margin: 1rem 0;
    font-size: 20px;
  }
  .flair-2 {
    top: 2;
  }
  .flair-1 {
    width: 44% !important;
    bottom: -16%;
    left: -3%;
  }
  .call-to-action {
    padding:30% 0;
  }
  .flair-about-2 {
    display: none;
  }
  .flair-about-1 {
    width: 29%;
  }
  .about-text {
    padding-bottom: 10rem;
  }
  .about-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .about-text {
    padding-bottom: 0;
  }
  .home-header {
    padding-bottom: 55%;
  }
  .header-image {
    width: 80% !important;
  }
  .header-image-2 {
    width: 80%;
  }
  .flair-about-1 {
    width: 29% !important;
    top: 54%;
    left: -5%;
  }
  .about-text {
    padding-bottom: 14rem;
  }
  .learning-text {
    padding-bottom: 13rem;
  }
  .header-image-3 {
    width: 77% !important;
    bottom: 0;
    left: -14%;
  } 
  .learning-header-flair-2 {
    left: 76% !important;
    width: 30% !important;
    top: 46% !important;
  }
  .row2 {
    width: 70%;
  }
  .technologies {
    margin: 8% 0;
  }

  .reach-image {
    width: 90% !important;
  }
  .logos {
    padding: 3rem 0 2rem 0;
  }
  .stallone-image {
    width: 100%;
  }
  
}

// Small devices 
@media (max-width: 576px) { 

  .card-body p {
    font-size: 15px;
  }
  .row2 {
    width: 70%;
  }
  .about-text {
    width:60%;
  }
 }

 // landscape header sizes 

 @media (max-width: 667px) { 
  .area-text {
    width: 70%;
  } 
  .reach-image {
    width: 90% !important;
  }
}
@media (max-width: 375px) { 
  .area-text {
    width: 62%;
  } 
  .about-text{
    width:90%;
  }

  .learning-text {
    width: 100%;
  }

}

 @media (max-width: 568px) { 
    .header-text {
      max-width: 65%;
    }

    .learning-text {
      width: 65%;
    }
  

 }
 @media (max-width: 414px) { 
  .header-text {
    max-width: 100%;
  } 
  .about-text,
  .learning-text {
    width:90%;
  }
  .foot-logos {
    display: flex;
    justify-content: center;
  }
  .area-image {
    width: 4rem !important;
}
.testimonial-portrait {
  width: 80;
}

}


// Medium devices (tablets, 768px and up)
@media (max-width: 770px) { 
  .login.btn.btn-lg {
    background-color:  #5FBECF;
    color: white;
    border-bottom: 1px solid white;
    display: inline-block;
    text-align: left;
    width: 95%;
    border-radius: 0;
    padding: .6rem;
    font-size: 18px;
    padding-left: 15px;
  }

  .header-text h1, 
  .about-text h1,
  .learning-text h1  {
    font-size: 33px; 
  }
  .header-text h1, 
  .header-text p {
    text-align:left;
  }
  .about-text p,
  .header-text p,
  .learning-text p {
    font-size:18px;
    
  }
  .nav-item {
    padding-left: 0px;
  }
  .card-image {
    display: none;
  }
  .areas-of-focus h1 {
    font-size:23px;
  }
  .area-text h2,
  .area-text p {
    font-size: 15px;
  }
  .nav-logo img {
    width: 7rem;
  }
  .area-image {
    width:5rem;
  }
  .area {
    margin-bottom: 2rem;
  }
  .purpose-header {
    font-size: 20px;
  }
  .purpose-text  {
    font-size: 16px;
  }
  .module-text p {
    font-size:15px;
  }
  .card-body {
    padding: 0;
    margin: 1rem 0;
  }
  
  .call-text h1 {
    font-size: 2rem;
  }
  .flair-about-1 {
    width: 18%;
    position: absolute;
    top: 58%;
    left: -5%
  }
  .flair-about-2 {
    width: 13%;
    top: 1%;
    right: -5%;
  }
  .flair-about-2 {
    width: 14%;
    top: 5%;
    right: -5%;
  }
  .header-image-3 {
    width: 40%;
    bottom: 0;
    left: -17%;
  }
  .learning-header-flair-2 {
    position: absolute;
    left: 87%;
    width: 16%;
    top: 0%;
  }
  .testimonial-portrait {
    width: 70%;
  }
  .nav-logo {
    margin-left: 1.5%;
   }

   .our-funders h1 {
    margin: 2rem 0 2rem 0 !important;
  }
  .ontario {
    width:16rem;
  }
  // .health {
  //   width:9rem;
  // }
  .justice {
    width:19rem;
  }
  .trillium {
    width: 10rem;
  }
  .telus {
    width: 5.5rem;
  }
  .rbc {
    width: 9.5rem;
  }
  .td {
    width: 5.5rem;
  }
  .row2 {
    width: 70%;
  }
  .tech h2 {
    font-size: 16px !important;
    padding: 0 !important;
  }
  .technologies.justify-content-center {
    margin: 4% 0;
  }

 
}
@media (max-width: 834px) {
  .card-image {
    display: none;
  }
  .our-reach.container-fluid {
    padding: 0 0 6% 0;
  }
  .reach-image  {
    position: static;
    width: 90%;
    margin: 0 auto;
  }
  .header-springboard-logo {
    margin-bottom: 2rem;
  }
}
// Large devices (desktops, 992px and up)
@media (max-width: 992px) { 
  #navbarNav {
    background-color: #5FBECF; 
    margin-top: 10px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: white !important;
    
  }
  .nav-item {
    padding: .2rem;
    border-bottom: 1px solid white;
    padding-left: 15px;
  }
  .join.btn.btn-lg {
    position:static;
    background-color: #5FBECF;
    padding: .6rem;
    font-size: 18px;
    padding-left: 15px;
  }
  .login.btn.btn-lg {
    background-color:  #5FBECF;
    color: white;
    border-bottom: 1px solid white;
    display: inline-block;
    text-align: left;
    width: 98%;
    border-radius: 0;
    padding: .6rem;
    font-size: 18px;
    padding-left: 15px;
  }

   
  } 


@media (max-width: 1024px) { 
  .module-text p {
    font-size:15px;
  }
  .area-image {
    width:5rem;
  }
  .about-text p,
  .header-text p,
  .learning-text p {
    font-size: 20px;
  }
  .tech h2 {
    font-size: 15px;
    margin-bottom: 0;
  }
  .tech-text {
    width: 100%;
    margin-left: 17px;
  }

  .purpose-content p {
    font-size: 15px;
  }
  .purpose-content {
    padding: 6% 0 4% 0;
  }
  .reach-image  {
    right: 52%;
    bottom: 15%;
  }
  .reach-header {
    margin: .6rem 0;
  }
  .flair-1 {
    width: 16%;
  }
  .flair-about-2 {
    top: 9%;
  }
  .our-reach h3,
  .purpose h3,
  .areas-of-focus h3,
  .purpose-content h3,
  .modules h3 {
    font-size: 22px;
  }
  .technologies {
    height: auto;
  }
  .tech {
    margin-bottom: 20px;
  }
  .technologies {
    margin: 0;
  }
  .testimonial-portrait {
    margin-bottom: 1rem;
    width:50%;
  }
  .learning-tech {
    margin-top: 0;
  }

  .our-funders h1 {
    margin: 5rem 0 3rem 0;
  }
  .ontario {
    max-width:17rem;
  }
  // .health {
  //   max-width:10rem;
  // }
  .justice {
    max-width:20rem;
  }
  .trillium {
    max-width: 12rem;
  }
  .telus {
    max-width: 7.5rem;
  }
  .rbc {
    max-width: 11.5rem;
  }
  .td {
    max-width: 7.5rem;
  }
  .technologies.justify-content-center {
    margin: 1% 0 3% 0;
  }

  // mobile nav 
  .navigation.container-fluid {
    padding: 0;
  }
  .navbar-toggler {
    padding: .25rem .75rem;
        padding-right: 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: 
    transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding-right: 9% !important;
  } 
  .nav-logo {
   padding-left: 8.5%;
  }
  .testimonial p {
    font-size: 15px;
  }
  .logos {
    padding: 3rem 0;
  }
} 
@media (max-width: 1199px) {
  .purpose-content {
    padding: 4% 0;
        padding-right: 0px;
        padding-left: 0px;
}
.technologies {
  margin-bottom: 2%
}
}
@media (min-width: 1112px) { 
  .header-text p {
    font-size:21px;
  }
  .logos {
    padding: 3rem 0 2rem 0;
}
}


// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
   .reach-image {
     width: 41%;
   }
 }
